import { useTranslation } from "next-i18next";
import Image from "next/image";

const Technologies = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center py-14">
        <div className="flex justify-center text-3xl md:text-4xl">
          <h2 className="text-indigo-600 pr-2">{t("technologies")}</h2>
          <h2 className="font-extralight">{t("we-use")}</h2>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-4/5 mx-auto">
        <div className="flex justify-center">
          <Image
            src={"/images/nextjs.svg"}
            alt="NextJS"
            width={300}
            height={300}
          />
        </div>
        <div className="flex justify-center">
          <Image
            src={"/images/html-js-css.svg"}
            alt="html-js-css"
            width={300}
            height={300}
          />
        </div>

        <div className="flex justify-center mb-14">
          <Image
            src={"/images/design-tools.svg"}
            alt="Design Tools"
            width={300}
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default Technologies;
